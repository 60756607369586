import React from "react";
import { useNavigate } from "react-router-dom";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, BASE_URL_IMG, LOGOUT } from "../../Config/api.config";

import { ReactComponent as Settings } from "../../assets/img/settings.svg";
import { ReactComponent as Logout } from "../../assets/img/logout.svg";
import { useTranslation } from "react-i18next";

interface DropdownMenuEmployerProps {
  setOnlineStatusOff: () => void;
}

export default function DropdownMenuEmployer({
  setOnlineStatusOff,
}: DropdownMenuEmployerProps) {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);

  const [decorationBar, setDecorationBar] = useLocalStorage(
    "barWorker",
    "info"
  );

  const navigate = useNavigate();

  const data = new FormData();
  data.append("id", userID);

  const logoutUser = () => {
    fetch(`${BASE_URL}${LOGOUT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then(() => handleLogout())
      .catch((err) => console.log(err));
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  function DropdownItem(props: {
    leftIcon?: any;
    children: React.ReactNode;
    rightIcon?: any;
  }) {
    return (
      <a className="h-10 flex text-sm items-center  p-1 hover:bg-[#f7f4f4] bg-white rounded cursor-pointer">
        <span className="mx-2 mr-4">{props.leftIcon}</span>
        {props.children}
        <span className="ml-auto">{props.rightIcon}</span>
      </a>
    );
  }
  return (
    <div className="relative">
      <div className="absolute shadow rounded top-10 -right-4 w-[240px] overflow-hidden z-10 bg-white py-2.5">
        <div className="flex flex-col items-center pt-2 pb-3">
          {userInfo.profile_image && (
            <img
              src={`${BASE_URL_IMG}${userInfo.profile_image}`}
              alt="profile"
              className="h-24  w-24 object-cover rounded-full cursor-default"
            ></img>
          )}
          {!userInfo.profile_image && (
            <p className="border-2 font-bold rounded-full border-black text-black h-[44px] w-[44px] p-1 flex mr-2 justify-center items-center">
              {userInfo.first_name?.slice(0, 1)}
              {userInfo?.last_name?.slice(0, 1)}
            </p>
          )}
          <p className="text-base font-bold py-2 cursor-default">
            {userInfo?.first_name} {userInfo?.last_name}
          </p>
        </div>
        <div
          onClick={() => {
            setDecorationBar("info");
            navigate("/settings");
          }}
        >
          <DropdownItem leftIcon={<Settings />}>
            {t("employer.leftbar.usermenu.link2")}
          </DropdownItem>
        </div>
        <div
          onClick={() => {
            setOnlineStatusOff();
            logoutUser();
            window.localStorage.clear();
          }}
        >
          <DropdownItem leftIcon={<Logout />}>
            {t("employer.leftbar.usermenu.link3")}
          </DropdownItem>
        </div>
      </div>
      <div className="bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute top-[30px] right-[22px] overflow-hidden  opacity-1 transition-opacity duration-300"></div>
    </div>
  );
}
