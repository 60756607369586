import { create } from "zustand";
import { combine } from "zustand/middleware";
import { profileTypeToken } from "./profileTypes";

const accessProfileKey = "profileTypes";

interface ProfileState {

  creditsNumber: string;
  setCreditsNumber: (credits: string) => void;
  applicantsNumber: number;
  setApplicantsNumber: (applicants: number) => void
  hiredNumber: number;
  setHiredNumber: (hired: number) => void
  offersNumber: number;
  setOffersNumber: (offers: number) => void

}

export const useEmployerStore = create<ProfileState>((set) => ({

  creditsNumber: "0",
  setCreditsNumber: (creditsNumber) => set({ creditsNumber }),
  applicantsNumber: 0,
  hiredNumber: 0,
  setApplicantsNumber: (applicantsNumber) => set({ applicantsNumber }),
  setHiredNumber: (hiredNumber) => set({ hiredNumber }),
  offersNumber: 0,
  setOffersNumber: (offersNumber) => set({ offersNumber })
}))

export const userProfileStore = create(
  combine({ profileTypeToken }, (set) => ({
    setProfile: async (x: any) => {
      try {
        await localStorage.setItem(accessProfileKey, x.profileTypeToken);
      } catch {
        // x;
      }
      set(x);
    },
    loadProfile: async () => {
      try {
        let profileTypeToken: any = await localStorage.getItem(accessProfileKey);
        profileTypeToken = profileTypeToken || "";

        set({ profileTypeToken });
      } catch {
        console.log(profileTypeToken);
      }
    },
  }))
);
