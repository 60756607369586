import React, { ReactNode, lazy } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
import ClosedViewJobPost from "src/Pages/Employer/ApplicationProcess/ClosedViewJobPost";
import { VerticalLayout } from "src/Pages/Worker/Layout/VerticalLayout";
import EnterpriseProfile from "src/Pages/Worker/Profile/EntrepriseProfile";
import ProfileReviews from "src/Pages/Worker/Profile/ProfileReviews";

const Legal = lazy(() => import("src/Pages/General/Legal/Legal"));
const UpdatePassword = lazy(
  () => import("src/Components/UpdatePassword/UpdatePassword")
);
const NewPassword = lazy(
  () => import("src/Components/UpdatePassword/NewPassword")
);

const TitleJob = lazy(
  () => import("src/Components/InitialClientForm/TitleJob")
);
const PasswordAndSecurity = lazy(
  () => import("src/Pages/Employer/Settings/PasswordAndSecurity")
);
const JobPostReview = lazy(
  () => import("src/Components/InitialClientForm/JobPostReview")
);
const JobPostEdit = lazy(
  () => import("src/Components/InitialClientForm/JobPostEdit")
);
const ViewJobPost = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/ViewJobPost")
);

const ReviewCandidates = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/ReviewCandidates")
);
const JobPostingsSearch = lazy(
  () => import("src/Components/AllKindOfJobs/JobPostingsSearch")
);
const JobDraftEdit = lazy(
  () => import("src/Components/InitialClientForm/JobDraftEdit")
);

const InviteWorkers = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/InviteWorkers")
);

const HiredApplicants = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/HiredApplicants")
);

const MessagesEmployer = lazy(
  () => import("src/Pages/Employer/Chat/MessagesEmployer")
);

const SendAnOffer = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/SendAnOffer")
);

const DraftsSearch = lazy(
  () => import("src/Components/AllKindOfJobs/DraftsSearch")
);

const ClosedJobsSearch = lazy(
  () => import("src/Components/AllKindOfJobs/ClosedJobsSearch")
);

const HiredWorkers = lazy(
  () => import("src/Pages/Employer/HiredWorkers/HiredWorkers")
);

const OffersApplicants = lazy(
  () => import("src/Pages/Employer/ApplicationProcess/OffersApplicants")
);

const Home = lazy(() => import("src/Pages/General/Home/Home"));

const Dashboard = lazy(() => import("src/Pages/Employer/Dashboard/Dashboard"));

const VerifyEmail = lazy(
  () => import("src/Pages/General/Register/VerifyEmail")
);

const Notifications = lazy(
  () => import("src/Pages/General/Notifications/Notifications")
);

const Step2Client = lazy(
  () => import("src/Pages/General/Register/Step2Client")
);

const Step1 = lazy(() => import("src/Pages/General/Register/Step1"));
const Login = lazy(() => import("src/Pages/General/Login/Login"));
const StepTwoWorker = lazy(
  () => import("src/Pages/General/Register/StepTwoWorker")
);

const ValidateEmail = lazy(
  () => import("src/Pages/General/ValidateEmail/ValidateEmail")
);
const NotFound = lazy(() => import("src/Pages/General/NotFound/NotFound"));
const WhereTo = lazy(() => import("src/Pages/Worker/Registration/WhereTo"));
const Step2Enterprise = lazy(
  () => import("src/Pages/General/Register/Step2Enterprise")
);
const Articles = lazy(() => import("src/Pages/General/Articles/Articles"));
const EmployerGuide = lazy(
  () => import("src/Components/Articles/EmployerGuide")
);

const WorkerGuide = lazy(() => import("src/Components/Articles/WorkerGuide"));

const Settings = lazy(() => import("src/Pages/General/Settings/Settings"));
const AboutUs = lazy(() => import("src/Pages/General/AboutUs/AboutUs"));

const Subscriptions = lazy(
  () => import("src/Pages/Employer/Subscriptions/Subscriptions")
);

const Pricing = lazy(() => import("src/Pages/Pricing/Pricing"));

const Contact = lazy(() => import("src/Pages/contact/Contact"));
const FindWorkers = lazy(
  () => import("src/Pages/Employer/FindWorkers/FindWorkers")
);

const WorkerProfile = lazy(
  () => import("src/Pages/Employer/FindWorkers/WorkerProfile")
);

const Credits = lazy(() => import("src/Pages/Employer/Credits/Credits"));

const JobPostInvite = lazy(
  () => import("src/Pages/Employer/JobPostInvite/JobPostInvite")
);

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

interface LayoutProps {
  children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <>
    <Outlet />
    {/* <VerticalLayout>{children}</VerticalLayout> */}
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="*" element={<NotFound />} />

      <Route path="/please-verify" element={<VerifyEmail />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/how-to-employer-guide" element={<EmployerGuide />} />
      <Route path="/how-to-worker-guide" element={<WorkerGuide />} />

      <Route path="/verify_email/:token/:id" element={<ValidateEmail />} />
      <Route path="/password/:token/:id" element={<NewPassword />} />
      <Route path="/forgot-password" element={<UpdatePassword />} />
      <Route
        path="/notifications"
        element={
          <VerticalLayout>
            <Notifications />
          </VerticalLayout>
        }
      />
      <Route
        path="/settings/password-and-security"
        element={<PasswordAndSecurity />}
      />
      <Route path="/create-profile/loading" element={<WhereTo />} />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Step1 />} />
      <Route path="/register-client" element={<Step2Client />} />
      <Route path="/register-enterprise" element={<Step2Enterprise />} />
      <Route path="/register-worker" element={<StepTwoWorker />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route
        path="/entrepise-profile"
        element={
          <VerticalLayout>
            <EnterpriseProfile />
          </VerticalLayout>
        }
      />

      <Route
        path="/entrepise-profile/reviews"
        element={
          <VerticalLayout>
            <ProfileReviews />
          </VerticalLayout>
        }
      />

      <Route
        path="/settings"
        element={
          <VerticalLayout>
            <Settings />
          </VerticalLayout>
        }
      />

      <Route path="/register-worker" element={<StepTwoWorker />} />
      <Route
        path="/welcome-client"
        element={
          <VerticalLayout>
            <TitleJob />
          </VerticalLayout>
        }
      />

      <Route
        path="/job-post-review"
        element={
          <VerticalLayout>
            <JobPostReview />
          </VerticalLayout>
        }
      />
      <Route
        path="/job-post-edit/:id"
        element={
          <VerticalLayout>
            <JobPostEdit />
          </VerticalLayout>
        }
      />
      <Route
        path="/job-draft-edit/:id"
        element={
          <VerticalLayout>
            <JobDraftEdit />
          </VerticalLayout>
        }
      />
      <Route
        path="/applicants/hired"
        element={
          <VerticalLayout>
            <HiredApplicants />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/applicants/offers"
        element={
          <VerticalLayout>
            <OffersApplicants />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/applicants/invite"
        element={
          <VerticalLayout>
            <InviteWorkers />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/applicants/view-job-post"
        element={
          <VerticalLayout>
            <ViewJobPost />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/applicants/view-closed-job-post"
        element={
          <VerticalLayout>
            <ClosedViewJobPost />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/applicants/review"
        element={
          <VerticalLayout>
            <ReviewCandidates />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/send-offer"
        element={
          <VerticalLayout>
            <SendAnOffer />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/all-jobs"
        element={
          <VerticalLayout>
            <JobPostingsSearch />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/all-closed-jobs"
        element={
          <VerticalLayout>
            <ClosedJobsSearch />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/all-drafts"
        element={
          <VerticalLayout>
            <DraftsSearch />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/dashboard"
        element={
          <VerticalLayout>
            <Dashboard />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/messages-em"
        element={
          <VerticalLayout>
            <MessagesEmployer />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/my-hires"
        element={
          <VerticalLayout>
            <HiredWorkers />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/subscriptions"
        element={
          <VerticalLayout>
            <Subscriptions />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/find-workers"
        element={
          <VerticalLayout>
            <FindWorkers />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/find-worker/:id"
        element={
          <VerticalLayout>
            <WorkerProfile />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/credits"
        element={
          <VerticalLayout>
            <Credits />
          </VerticalLayout>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/job-post/invite/:id"
        element={<JobPostInvite />}
        errorElement={<ErrorBoundary />}
      />
    </Route>
  )
);

export default function EmployerNavigation() {
  return <RouterProvider router={router} />;
}

// export default function EmployerNavigation() {
//   return <Routes></Routes>;
// }
