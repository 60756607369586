import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import AuthenticationSwitch from "./AuthenticationSwitch";

// const router = createBrowserRouter([
//   { path: "*", Component: AuthenticationSwitch },
// ]);

// 4️⃣ RouterProvider added
export default function Router() {
  return (
    <React.Suspense fallback={null}>
      <AuthenticationSwitch />
      {/* <RouterProvider router={router} /> */}
    </React.Suspense>
  );
}
