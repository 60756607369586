import React, { useState } from "react";
import { useEffect } from "react";
import { useAtom } from "jotai";

//LOCALS
import { userProfileStore } from "./useProfileStore";
import WorkerNavigation from "./Worker/WorkerNavigation";
import { language } from "../Atoms/jotaiAtoms";
import AuthNavigation from "./AuthNavigation";
import EmployerNavigation from "./Employer/EmployerNavigation";
import { BASE_URL } from "../Config/api.config";
import Loader from "src/Components/Loader/Loader";

const AuthenticationSwitch = () => {
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);
  const [languageChosen, setLanguageChosen] = useAtom(language);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}${languageChosen}`);
  }, [languageChosen]);

  useEffect(() => {
    setLoading(true);
    const profile = localStorage.getItem("profileTypes");
    if (profile !== null && profile !== undefined) {
      setProfileTypesLocal({ profileTypeToken: profile });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  // return <EmployerNavigation />;
  if (profileType === "employer" && !loading) {
    return <EmployerNavigation />;
  } else if (profileType === "worker" && !loading) {
    return <WorkerNavigation />;
  } else if ((profileType === "" || !profileType) && !loading) {
    return <AuthNavigation />;
  } else {
    return <Loader />;
  }
};

export default AuthenticationSwitch;
