let ApiUrl = "/labtest/elite-api-flexihours/v2/";
let HOST = "http://192.168.1.46/labtest/elite-api-flexihours/v1/";
export let BASE_URL_IMG = "http://192.168.1.46/img.flexihours/";

export const GIFT_ID = "12";

export let DOMAIN = "http://localhost:3000";

let DEVELOPMENT = 2;

if (DEVELOPMENT === 2) {
  DOMAIN = "https://flexihours.es";
  HOST = "https://api.flexihours.es/";

  BASE_URL_IMG = "https://img.flexihours.es/";
}
export let BASE_URL = HOST;

export let URL_IMG_PUBLIC = "https://public.flexihours.es/";

export let LIMIT = 8;

export const FEATURED_WORKER = "worker.profile.featured";

export const CONNECTION_REVIEWS = "connections.reviews";

export const CATEGORIES_WORKER = "worker.category";

export const PROFILE_WORKER = "worker.profile";

export const INFO_WORKER = "workers";

export const CLOSE_JOB_EMPLOYER = "employer.close.job";

export const DRAFTS = "drafts";

export const POSTINGS = "postings";

export const NOTIFICATIONS = "notifications";

export const LOGOUT = "logout";

export const CHAT = "chat";

export const SETTINGS_USER = "user.settings";

export const JOB_TIME_PROFILE = "job.time.profile";

export const LOCATIONS = "locations";

export const CITIES = "cities";

export const POSTCODES = "postcodes";

export const EXPERIENCE_WORKER = "worker.experience";

export const JOB_SKILLS = "job.skills";

export const JOB_CATEGORIES = "job.categories";

export const ACTIONS = "actions";

export const SAVED_JOBS_WORKER = "worker.saved.jobs";

export const JOBS = "jobs";

export const REPORT_CHAT_MESSAGES = "chat.messages.report";

export const DELETE_CHAT_MESSAGES = "chat.messages.delete";

export const EDUCATION_WORKER = "worker.education";

export const LANGUAGES = "languages";

export const LANGUAGE_LEVEL = "language_level";

export const LANGUAGE_WORKER = "worker.language";

export const CLOSE_JOB_REASONS = "close.job.reasons";

export const DECLINE_REASONS = "decline.reasons";

export const DECLINE_OFFER_REASONS = "decline.offer.reasons";

export const SAVED_JOB_SEARCH = "job.saved.search";

export const INAPROPIATE_JOB = "inapropiate.job";

export const INAPROPIATE_WORKER_PROFILE = "inapropiate.worker.profile";

export const INVITE_WORKERS_EMPLOYER = "employer.invite.workers";

export const EMPLOYER_DISCOVER_PROFILE = "employer.discover.profile";

export const WORKER_PROFILE_PROGRESS = "worker.profile.progress";

export const JOB_SAVED_SEARCH = "job.saved.search";

export const SCORE_CONNECTION = "score.connection";

export const COMPANY = "company";
