import { PencilIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import GoBackButton from "src/Components/ui/GoBack";
import useLocalStorage from "src/Hooks/UseLocalStorage";

const ProfileReviews = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);

  console.log("userInfo", userInfo);
  return (
    <div>
      <div
        className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer text-azulBonico hover:underline"
        onClick={() => navigate("/dashboard")}
      >
        <GoBackButton />
      </div>
      <div className="mt-10">
        <h1 className="text-lg font-bold">Profile's {userInfo.first_name}</h1>

        <div className="flex flex-col items-center py-4 relative">
          <div className="h-32 w-32 rounded-full bg-azulBonico font-semibold flex items-center justify-center text-white">
            {userInfo.employer_image}
          </div>
          <p className="text-xl font-semibold mt-4">
            {userInfo.first_name} {userInfo.last_name}
          </p>
          <button className="border-border w-32 flex items-center space-x-2 py-2 justify-center bg-azulBonico text-white rounded-full px-5  border absolute top-4 right-4">
            <PencilIcon className="h-4 w-4" />
            <p className="">Edit</p>
          </button>
        </div>
        <div className=" border-t border-border py-2">
          <h1 className="text-lg font-medium">About me </h1>
          <p>Hello, my name is {userInfo.first_name}</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileReviews;
