import React, { ReactNode, lazy } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
import Calendar from "src/Pages/General/Home/Calendar";
import { VerticalLayout } from "src/Pages/Worker/Layout/VerticalLayout";

const DashboardWorker = lazy(
  () => import("src/Pages/Worker/Dashboard/DashboardWorker")
);
const AddEducation = lazy(
  () => import("src/Pages/Worker/Registration/AddEducation")
);
const AddEmployment = lazy(
  () => import("src/Pages/Worker/Registration/AddEmployment")
);
const AddTitle = lazy(() => import("src/Pages/Worker/Registration/AddTitle"));
const WhereTo = lazy(() => import("src/Pages/Worker/Registration/WhereTo"));
const AddLanguages = lazy(
  () => import("src/Pages/Worker/Registration/AddLanguages")
);
const AddSkills = lazy(() => import("src/Pages/Worker/Registration/AddSkills"));
const AddBio = lazy(() => import("src/Pages/Worker/Registration/AddBio"));
const AddCategories = lazy(
  () => import("src/Pages/Worker/Registration/AddCategories")
);
const SubmitProfile = lazy(
  () => import("src/Pages/Worker/Registration/SubmitProfile")
);
const AddDetails = lazy(
  () => import("src/Pages/Worker/Registration/AddDetails")
);

const AddScheduleGood = lazy(
  () => import("src/Pages/Worker/Registration/AddScheduleGood")
);
const AddBudget = lazy(() => import("src/Pages/Worker/Registration/AddBudget"));
const ProfileReady = lazy(
  () => import("src/Pages/Worker/Registration/ProfileReady")
);
const Profile = lazy(() => import("src/Pages/Worker/Profile/Profile"));
const MessagesWorker = lazy(
  () => import("src/Pages/Worker/Chat/MessagesWorker")
);

const Settings = lazy(() => import("src/Pages/General/Settings/Settings"));
const JobSearch = lazy(() => import("src/Components/JobSearch/JobSearch"));
const OfferDetails = lazy(
  () => import("src/Components/ViewJobOffer/OfferDetails")
);
const WorkerProposals = lazy(
  () => import("src/Pages/Worker/Offers/WorkerProposals")
);
const ReceivedOffer = lazy(
  () => import("src/Components/ViewJobOffer/ReceivedOffer")
);
const AcceptedJobs = lazy(
  () => import("src/Pages/Worker/AcceptedJobs/AcceptedJobs")
);
const VerifyEmail = lazy(
  () => import("src/Pages/General/Register/VerifyEmail")
);
const Legal = lazy(() => import("src/Pages/General/Legal/Legal"));
const NewPassword = lazy(
  () => import("src/Components/UpdatePassword/NewPassword")
);

const ValidateEmail = lazy(
  () => import("src/Pages/General/ValidateEmail/ValidateEmail")
);

const UpdatePassword = lazy(
  () => import("src/Components/UpdatePassword/UpdatePassword")
);
const Notifications = lazy(
  () => import("src/Pages/General/Notifications/Notifications")
);

const PasswordAndSecurity = lazy(
  () => import("src/Pages/Employer/Settings/PasswordAndSecurity")
);

const NotFound = lazy(() => import("src/Pages/General/NotFound/NotFound"));
const Home = lazy(() => import("src/Pages/General/Home/Home"));
const Login = lazy(() => import("src/Pages/General/Login/Login"));
const Step1 = lazy(() => import("src/Pages/General/Register/Step1"));
const Step2Client = lazy(
  () => import("src/Pages/General/Register/StepTwoWorker")
);
const Articles = lazy(() => import("src/Pages/General/Articles/Articles"));
const EmployerGuide = lazy(
  () => import("src/Components/Articles/EmployerGuide")
);

const WorkerGuide = lazy(() => import("src/Components/Articles/WorkerGuide"));

const Step2Enterprise = lazy(
  () => import("src/Pages/General/Register/Step2Enterprise")
);
const AboutUs = lazy(() => import("src/Pages/General/AboutUs/AboutUs"));
const Pricing = lazy(() => import("src/Pages/Pricing/Pricing"));
const Contact = lazy(() => import("src/Pages/contact/Contact"));
const Subscriptions = lazy(
  () => import("src/Pages/Worker/Subscriptions/Subscriptions")
);
const FindWork = lazy(() => import("src/Pages/Worker/FindWork/FindWork"));

const Reports = lazy(() => import("src/Pages/Worker/Reports/Reports"));

function ErrorBoundary() {
  const error = useRouteError();
  console.log(error);
  return <div>Dang!</div>;
}
interface LayoutProps {
  children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => (
  <>
    <Outlet />
    {/* <VerticalLayout>{children}</VerticalLayout> */}
  </>
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route
        errorElement={<ErrorBoundary />}
        path="/aboutus"
        element={<AboutUs />}
      />

      <Route
        errorElement={<ErrorBoundary />}
        path="/please-verify"
        element={<VerifyEmail />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/legal"
        element={<Legal />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/articles"
        element={<Articles />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/how-to-employer-guide"
        element={<EmployerGuide />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/how-to-worker-guide"
        element={<WorkerGuide />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/verify_email/:token/:id"
        element={<ValidateEmail />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/password/:token/:id"
        element={<NewPassword />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/forgot-password"
        element={<UpdatePassword />}
      />
      {/* <Route  errorElement={<ErrorBoundary />} path="/reset-password" element={<NewPassword />} /> */}
      <Route
        errorElement={<ErrorBoundary />}
        path="/notifications"
        element={
          <VerticalLayout>
            <Notifications />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/settings/password-and-security"
        element={
          <VerticalLayout>
            <PasswordAndSecurity />
          </VerticalLayout>
        }
      />
      <Route errorElement={<ErrorBoundary />} path="*" element={<NotFound />} />
      <Route errorElement={<ErrorBoundary />} path="/" element={<Home />} />
      <Route
        errorElement={<ErrorBoundary />}
        path="/login"
        element={<Login />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/register"
        element={<Step1 />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/register-client"
        element={<Step2Client />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/register-enterprise"
        element={<Step2Enterprise />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/register-worker"
        element={<Step2Client />}
      />
      {/* <Route  errorElement={<ErrorBoundary />} path="/view-profile" element={<ViewApplicantProfile />} /> */}
      <Route
        errorElement={<ErrorBoundary />}
        path="/dashboard"
        element={
          <VerticalLayout>
            <DashboardWorker />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/loading"
        element={
          <VerticalLayout>
            <WhereTo />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/title"
        element={<AddTitle />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/employment"
        element={<AddEmployment />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/education"
        element={<AddEducation />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/languages"
        element={<AddLanguages />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/skills"
        element={<AddSkills />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/bio"
        element={<AddBio />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/categories"
        element={<AddCategories />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/submit"
        element={<SubmitProfile />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/details"
        element={<AddDetails />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/schedule"
        element={<AddScheduleGood />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/budget"
        element={<AddBudget />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/finish"
        element={<ProfileReady />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/profile"
        element={
          <VerticalLayout>
            <Profile />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/messages"
        element={
          <VerticalLayout>
            <MessagesWorker />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/settings"
        element={<Settings />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/jobs-search"
        element={
          <VerticalLayout>
            <JobSearch />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/job-offer/:offerId"
        element={
          <VerticalLayout>
            <OfferDetails />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/applied-jobs"
        element={
          <VerticalLayout>
            <WorkerProposals />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/view-offer"
        element={
          <VerticalLayout>
            <ReceivedOffer />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/my-jobs"
        element={
          <VerticalLayout>
            <AcceptedJobs />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/pricing"
        element={<Pricing />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/contact"
        element={<Contact />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/subscriptions"
        element={<Subscriptions />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/find-work"
        element={
          <VerticalLayout>
            <FindWork />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/calendar"
        element={
          <VerticalLayout>
            <Calendar />
          </VerticalLayout>
        }
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/reports"
        element={
          <VerticalLayout>
            <Reports />
          </VerticalLayout>
        }
      />
    </Route>
  )
);
export default function WorkerNavigation() {
  return <RouterProvider router={router} />;
}
