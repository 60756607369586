import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";

import { cn } from "src/lib/utils";

const Loader = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const [animationData, setAnimationData] = useState<any>();

  useEffect(() => {
    import("../../Utils/lotties/loader.json").then(setAnimationData);
  }, []);

  return (
    <div className="flex items-center justify-center bg-transparent h-[90vh] w-screen">
      <div className={cn(className)}>
        <Lottie
          aria-aria-labelledby="lottie loader"
          animationData={animationData}
          loop
          className="w-32 h-32"
        />
      </div>
    </div>
  );
};
export default Loader;
