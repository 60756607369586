import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, BASE_URL_IMG, LOGOUT } from "../../Config/api.config";
import {
  jobOfferAtom,
  navBarSettingsWorker,
  savedSearchesData,
  selectedTabHeaderEmployer,
  selectedTabHeaderWorker,
} from "../../Atoms/jotaiAtoms";

import { ReactComponent as Settings } from "../../assets/img/settings.svg";
import { ReactComponent as Logout } from "../../assets/img/logout.svg";
import { useTranslation } from "react-i18next";

interface DropdownMenuProps {
  setOnlineStatusOff: () => void;
}

export default function DropdownMenu({
  setOnlineStatusOff,
}: DropdownMenuProps) {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [uid, setUid] = useLocalStorage("UID", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [decorationBar, setDecorationBar] = useAtom(navBarSettingsWorker);

  const [profileType, setProfileType] = useLocalStorage("profileType", "");
  const [headerWorker, setHeaderWorker] = useAtom(selectedTabHeaderWorker);
  const [savedSearches, setSavedSearches] = useAtom(savedSearchesData);
  const [headerEmployer, setHeaderEmployer] = useAtom(
    selectedTabHeaderEmployer
  );
  const [jobOffers, setJobOffers] = useAtom(jobOfferAtom);

  const navigate = useNavigate();

  const data = new FormData();
  data.append("id", userID);

  const logoutUser = () => {
    fetch(`${BASE_URL}${LOGOUT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then(() => handleLogout())
      .catch((err) => console.log(err));
  };
  const resetAtoms = () => {
    if (profileType === "worker") {
      setHeaderWorker("dashboard");
      setSavedSearches();
      setJobOffers([]);
    } else if (profileType === "employer") {
      setJobOffers([]);
      setHeaderEmployer("dashboard");
    }
  };

  const handleLogout = () => {
    resetAtoms();
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  //CHANGE STATUS CHAT VISIBILITY

  function DropdownItem(props: {
    leftIcon: ReactNode;
    children: ReactNode;
    rightIcon?: ReactNode;
  }) {
    return (
      <a className="h-10 flex text-sm items-center  p-1 hover:bg-[#f7f4f4] bg-white rounded cursor-pointer">
        <span className="mx-2 mr-4">{props.leftIcon}</span>
        {props.children}
        <span className="ml-auto">{props.rightIcon}</span>
      </a>
    );
  }
  return (
    <div className="relative">
      <div className="absolute shadow-[0px_0px_5px_1px_rgba(0,0,0,0.2)] rounded top-10 right-4 w-[240px] overflow-hidden z-10 bg-white py-2.5">
        <div className="flex flex-col items-center pt-2 pb-3">
          <div className="relative">
            <img
              src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
              alt="profile"
              className="h-24 w-24 object-cover rounded-full cursor-default"
            ></img>
          </div>
          <p className="text-base font-bold py-2 cursor-default">
            {userInfo?.first_name} {userInfo?.last_name}
          </p>
        </div>
        <div
          onClick={() => {
            setDecorationBar("info");
            navigate("/settings");
          }}
        >
          <DropdownItem leftIcon={<Settings />}>
            {t("employer.leftbar.usermenu.link2")}
          </DropdownItem>
        </div>
        <div
          onClick={() => {
            setOnlineStatusOff();
            logoutUser();
            window.localStorage.clear();
          }}
        >
          <DropdownItem leftIcon={<Logout />}>
            {t("employer.leftbar.usermenu.link3")}
          </DropdownItem>
        </div>
      </div>
      <div className="bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute top-[30px] right-10 overflow-hidden  opacity-1 transition-opacity duration-300"></div>
    </div>
  );
}
