import React, { ReactNode, lazy } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
const Legal = lazy(() => import("src/Pages/General/Legal/Legal"));
const VerifyEmail = lazy(
  () => import("src/Pages/General/Register/VerifyEmail")
);

const ValidateEmail = lazy(
  () => import("src/Pages/General/ValidateEmail/ValidateEmail")
);

const NewPassword = lazy(
  () => import("src/Components/UpdatePassword/NewPassword")
);

const UpdatePassword = lazy(
  () => import("src/Components/UpdatePassword/UpdatePassword")
);

const Notifications = lazy(
  () => import("src/Pages/General/Notifications/Notifications")
);

const PasswordAndSecurity = lazy(
  () => import("src/Pages/Employer/Settings/PasswordAndSecurity")
);

const WhereTo = lazy(() => import("src/Pages/Worker/Registration/WhereTo"));
const Home = lazy(() => import("src/Pages/General/Home/Home"));
const Login = lazy(() => import("src/Pages/General/Login/Login"));
const Step1 = lazy(() => import("src/Pages/General/Register/Step1"));
const Step2Client = lazy(
  () => import("src/Pages/General/Register/Step2Client")
);
const Step2Enterprise = lazy(
  () => import("src/Pages/General/Register/Step2Enterprise")
);
const StepTwoWorker = lazy(
  () => import("src/Pages/General/Register/StepTwoWorker")
);
const Articles = lazy(() => import("src/Pages/General/Articles/Articles"));
const EmployerGuide = lazy(
  () => import("src/Components/Articles/EmployerGuide")
);
const WorkerGuide = lazy(() => import("src/Components/Articles/WorkerGuide"));
const AboutUs = lazy(() => import("src/Pages/General/AboutUs/AboutUs"));
const Pricing = lazy(() => import("src/Pages/Pricing/Pricing"));
const NotFound = lazy(() => import("src/Pages/General/NotFound/NotFound"));
const Contact = lazy(() => import("src/Pages/contact/Contact"));

function ErrorBoundary() {
  const error = useRouteError();
  console.log(error);
  return <div>Dang!</div>;
}
interface LayoutProps {
  children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => (
  <>
    <Outlet />
    {/* <VerticalLayout>{children}</VerticalLayout> */}
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route
        path="/aboutus"
        element={<AboutUs />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/please-verify"
        element={<VerifyEmail />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/legal"
        element={<Legal />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/articles"
        element={<Articles />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/how-to-employer-guide"
        element={<EmployerGuide />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/how-to-worker-guide"
        element={<WorkerGuide />}
        errorElement={<ErrorBoundary />}
      />

      <Route path="*" element={<NotFound />} />

      <Route
        path="/verify_email/:token/:id"
        element={<ValidateEmail />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/password/:token/:id"
        element={<NewPassword />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/forgot-password"
        element={<UpdatePassword />}
        errorElement={<ErrorBoundary />}
      />

      {/* <Route path="/reset-password" element={<NewPassword />} /> */}

      <Route
        path="/notifications"
        element={<Notifications />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/settings/password-and-security"
        element={<PasswordAndSecurity />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/create-profile/loading"
        element={<WhereTo />}
        errorElement={<ErrorBoundary />}
      />

      {/* */}
      <Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />
      {/* </Suspense> */}

      <Route
        path="/login"
        element={<Login />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register"
        element={<Step1 />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-client"
        element={<Step2Client />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-enterprise"
        element={<Step2Enterprise />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-worker"
        element={<StepTwoWorker />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/pricing"
        element={<Pricing />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/contact"
        element={<Contact />}
        errorElement={<ErrorBoundary />}
      />
    </Route>
  )
);

export default function AuthNavigation() {
  return <RouterProvider router={router} />;
}
