import React, { useState, useEffect } from "react";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import JobDetails from "../../../Components/ViewJobPost/JobDetails";
import ProgressBarOffer from "../../../Components/ViewJobPost/ProgressBarOffer";
import { BASE_URL } from "../../../Config/api.config";

import { VerticalLayout } from "../../Worker/Layout/VerticalLayout";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";
import { ReactComponent as Check } from "../../../assets/img/check.svg";
import { useViewJobPost } from "src/Hooks/useViewJobPost";
import { useQuery } from "react-query";

export default function ClosedViewJobPost() {
  const [activateConfetti, setActivateConfetti] = useState(false);
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [allTimes, setAllTimes] = useState([]);

  const [counter, setCounter] = useState({ applicants: "", hired: "" });
  const { t } = useTranslation();
  const { isLoadingTime, timesData, applicantsData, hiresData } =
    useViewJobPost();

  const getClosedJobData = async () => {
    const data = await fetch(`${BASE_URL}employer.close.job?job_id=${jobID}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: editJobData,
    refetch: refetchClosedJobData,
    isLoading: isLoadingJobData,
  } = useQuery(["closed_posting", jobID], getClosedJobData);

  useEffect(() => {
    setJobData(editJobData);
  }, [editJobData]);

  useEffect(() => {
    setAllTimes(timesData);
  }, [timesData]);

  useEffect(() => {
    setCounter((prev) => ({ ...prev, applicants: applicantsData?.length }));
  }, [applicantsData]);

  useEffect(() => {
    if (hiresData?.length > 0) {
      setCounter((prev: any) => ({
        ...prev,
        hired: hiresData?.length,
      }));
    }
  }, [hiresData]);

  useEffect(() => {
    if (!jobData) {
      refetchClosedJobData();
    }
  }, [jobData]);

  return (
    <>
      {activateConfetti && (
        <>
          <Confetti
            numberOfPieces={300}
            gravity={0.3}
            initialVelocityY={150}
            initialVelocityX={10}
          />
          <div
            className={`flex justify-center transition-opacity duration-700 ${
              !activateConfetti ? "opacity-0 " : "opacity-100 "
            }`}
          >
            <div className="bg-green-600 text-white font-bold text-xl rounded-lg py-3.5 tracking-wide px-6 mt-12 fixed items-start shadow-lg">
              <div className="flex justify-between gap-[78px] items-center">
                <div className="flex gap-4 items-center">
                  <Check className="h-8 fill-white" />
                  <p>{t("employer.job_posting_cloned")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!activateConfetti && (
        <>
          {/* <div
            className="flex items-center px-8 mt-6 gap-2 fill-azulBonico text-azulBonico cursor-pointer hover:underline"
            onClick={() => navigate("/dashboard")}
          >
            <GoBackButton />
          </div> */}

          <ProgressBarOffer
            props={"view"}
            jobData={jobData && jobData?.data?.[0].job_status}
          />

          <JobDetails
            isJobLoading={isLoadingJobData}
            loadingTime={isLoadingTime}
            allChosenTimes={allTimes}
            jobData={jobData?.data ? jobData?.data?.[0] : jobData}
            skills={jobData?.skills}
            counter={counter}
            setActivateConfetti={setActivateConfetti}
          />
        </>
      )}
    </>
  );
}
